.react-select {
  &__input-container{
    padding: 0 !important;
    margin: 0 !important;
  }
  &-error__input-container{
    padding: 0 !important;
    margin: 0 !important;
  }
  &__control {
    display: flex !important;
    background-color: transparent !important;
    width: 100% !important;
    padding: 10px 0 !important;
    border: 1px solid theme('colors.tertiary.DEFAULT') !important;
    box-shadow: none;
    border-radius: 0 !important;

    &--is-focused {
      box-shadow: none !important;
      outline: none !important;
      // border: 1px solid black !important;
    }
  }
  &__single-value {
    color: theme('colors.primary.400') !important;
    font-weight: 600 !important;
  }
  &-error__control {
    display: flex !important;
    background-color: transparent !important;
    width: 100% !important;
    padding: 10px 0 !important;
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid theme('colors.danger.DEFAULT') !important;

    &--is-focused {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  &__indicator-separator,
  &-error__indicator-separator {
    display: none !important;
  }
  &__dropdown-indicator {
    padding: 0 8px !important;
  }
  &-error__dropdown-indicator {
    padding: 0 8px !important;
  }
  &__dropdown-indicator svg,
  &-error__dropdown-indicator svg {
    color: theme('colors.primary.400') !important;
    width: 12px !important;
    height: 8px !important;
  }
  &__menu,
  &-error__menu {
    margin-top: 0 !important;
    border: 1px solid theme('colors.tertiary.DEFAULT') !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    &-list {
      .react-select__option {
        padding: 8px 4px !important;
        font-weight: 600 !important;
      }
    }
  }
}
// .react-select__placeholder {
//   text-transform: uppercase !important;
// }

@media screen and (min-width: 1024px) {
  .react-select {
    &__control {
      padding: 18px 5px !important;
    }
    &-error__control {
      padding: 18px 5px !important;
    }
    &__dropdown-indicator svg,
    &-error__dropdown-indicator svg {
      width: 20px !important;
      height: 12px !important;
    }
    &__menu,
    &-error__menu {
      &-list {
        .react-select__option {
          padding: 16px 8px !important;
        }
      }
    }
  }
}
