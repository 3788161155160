.wpwl-container .wpwl-container-card {
  width: 1000px !important;
}
.wpwl {
  &-form {
    background: var(--color-tertiary-50) !important;
    background-image: none !important;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    padding: 32px !important;
    font-family: var(--fontFamily-secondary) !important;
    width: 100% !important;
    font-size: 16px !important;
  }
}
.wpwl-form .wpwl-label {
  padding-bottom: 5px !important;
  font-size: 12px !important;
  color: var(--color-primary-100);
}

.wpwl-control {
  // padding: 1.5rem 1rem !important;
  border-radius: 0 !important;
  border: 1px solid var(--color-tertiary) !important;
  outline: none !important;
  background: #00000000 !important;
  color: var(--color-black) !important;
  height: 40px;
  font-family: var(--fontFamily-secondary) !important;
  padding: 8px !important;
  font-weight: 500;
}

.wpwl-control::placeholder {
  // color: #00000075 !important;
  color: var(--color-primary-100) !important;
  opacity: .9 !important;
  // font-weight: 600 !important;
  text-transform: capitalize !important;
}

// .wpwl-contol-cardNumber,
// .wpwl-control-expiry,
// .wpwl-control-cardHolder,
// .wpwl-control-cvv {
//   padding: 0.5rem !important;
//   font-weight: 500;
//   height: 2.5rem !important;
//   font-family: var(--fontFamily-secondary) !important;
// }

.wpwl-control-brand {
  // padding: 0 0.2rem !important;
  height: 40px !important;
  font-family: var(--fontFamily-secondary) !important;
  font-weight: 500;
}

.wpwl-form .wpwl-button-pay {
  background: var(--color-lightGreen) !important;
  color: var(--color-white) !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border-radius: 0 !important;
  padding: 12px 32px !important;
  margin-top: 16px !important;
  outline: none !important;
  border: none !important;
  font-family: var(--fontFamily-secondary) !important;
}

.wpwl-form .wpwl-sup-wrapper {
  margin-bottom: 1rem !important;
}

@media screen and (min-width: 1024px) {
  .wpwl {
    &-form {
      padding: 40px !important;
      min-width: 720px !important;
    }
  }
  .wpwl-control {
    min-height: 62px !important;
    padding: 0 24px !important;
  }
  .wpwl-label-brand {
    min-height: 62px !important;
    display: flex !important;
    align-items: center !important;
    margin-right: 24px !important;
  }
  .wpwl-control-brand {    //  padding: 1rem !important;
    min-width: 393px !important;
  }
  .wpwl-form .wpwl-button-pay {
    width: 211px !important;
    height: 61px !important;
    margin-top: 24px !important;
    text-transform: uppercase !important;
  }
}

@media screen and (min-width: 1200px) {
  .wpwl {
    &-form {
      padding: 56px !important;
      min-width: 760px !important;
    }
  }
  .wpwl-form .wpwl-button-pay {
    margin-top: 40px !important;
  }
}
