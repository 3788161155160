@tailwind base;
@tailwind components;
@tailwind utilities;

#__MTS *,
#__MTS::after,
#__MTS ::before {
  box-sizing: border-box;
}
#__MTS html {
  -moz-tab-size: 4;
  tab-size: 4;
}
#__MTS html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
#__MTS body {
  margin: 0;
}
#__MTS body {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}
#__MTS hr {
  height: 0;
  color: inherit;
}
#__MTS abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
#__MTS b,
#__MTS strong {
  font-weight: bolder;
}
#__MTS code,
#__MTS kbd,
#__MTS pre,
#__MTS samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  font-size: 1em;
}
#__MTS small {
  font-size: 80%;
}
#__MTS sub,
#__MTS sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
#__MTS sub {
  bottom: -0.25em;
}
#__MTS sup {
  top: -0.5em;
}
#__MTS table {
  text-indent: 0;
  border-color: inherit;
}
#__MTS button,
#__MTS input,
#__MTS optgroup,
#__MTS select,
#__MTS textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
#__MTS select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
#__MTS button {
  -webkit-appearance: button;
}
#__MTS ::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
#__MTS :-moz-focusring {
  outline: 1px dotted ButtonText;
}
#__MTS :-moz-ui-invalid {
  box-shadow: none;
}
#__MTS legend {
  padding: 0;
}
#__MTS progress {
  vertical-align: baseline;
}
#__MTS ::-webkit-inner-spin-button,
#__MTS ::-webkit-outer-spin-button {
  height: auto;
}
#__MTS [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
#__MTS ::-webkit-search-decoration {
  -webkit-appearance: none;
}
#__MTS ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
#__MTS summary {
  display: list-item;
}
#__MTS blockquote,
#__MTS dd,
#__MTS dl,
#__MTS figure,
#__MTS h1,
#__MTS h2,
#__MTS h3,
#__MTS h4,
#__MTS h5,
#__MTS h6,
#__MTS hr,
#__MTS p,
#__MTS pre {
  margin: 0;
}
#__MTS button {
  background-color: transparent;
  background-image: none;
}
#__MTS fieldset {
  margin: 0;
  padding: 0;
}
ol,
#__MTS ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#__MTS html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
}
#__MTS body {
  font-family: inherit;
  line-height: inherit;
}
#__MTS *,
#__MTS ::after,
#__MTS ::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
#__MTS hr {
  border-top-width: 1px;
}
#__MTS img {
  border-style: solid;
}
#__MTS textarea {
  resize: vertical;
}
#__MTS input::placeholder,
#__MTS textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
#__MTS [role='button'],
#__MTS button {
  cursor: pointer;
}
#__MTS :-moz-focusring {
  outline: auto;
}
#__MTS table {
  border-collapse: collapse;
}
#__MTS h1,
#__MTS h2,
#__MTS h3,
#__MTS h4,
#__MTS h5,
#__MTS h6 {
  font-size: inherit;
  font-weight: inherit;
}
#__MTS a {
  color: inherit;
  text-decoration: inherit;
}
#__MTS button,
#__MTS input,
#__MTS optgroup,
#__MTS select,
#__MTS textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}
#__MTS code,
#__MTS kbd,
#__MTS pre,
#__MTS samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
#__MTS audio,
#__MTS canvas,
#__MTS embed,
#__MTS iframe,
#__MTS img,
#__MTS object,
#__MTS svg,
#__MTS video {
  display: block;
  vertical-align: middle;
}
#__MTS img,
#__MTS video {
  max-width: 100%;
  height: auto;
}
#__MTS [hidden] {
  display: none;
}
*,
#__MTS ::after,
#__MTS ::before {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

@layer base {
  body {
    font-family: Georgia, "Times New Roman", Times, serif, sans-serif;
  }
}
