.react-calendar {
  display: flex !important;
  flex-direction: column !important;
  /* width: 100% !important; */
  /* max-width: 100% !important; */
  background-color: var(--color-white) !important;
  color: var(--color-sandstone-500) !important;
  border: 1px solid var(--color-whitesmoke-500) !important;
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2) !important; */
  font-family: isidoraSansAlt, Helvetica, sans-serif !important;
  z-index: 100 !important;
}
.react-calendar__navigation {
  position: relative !important;
  display: flex !important;
  justify-content: space-evenly !important;
  padding: 8px 10px !important;
}
.react-calendar__navigation::after {
  content: '' !important;
  position: absolute !important;
  border-bottom: 1px solid var(--color-whitesmoke-500) !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
}
.react-calendar__navigation__label {
  font-weight: 600 !important;
  color: var(--color-sandstone-500) !important;
}
.react-calendar__navigation__arrow {
  font-size: 30px !important;
  color: var(--color-black) !important;
  width: 1rem !important;
}
.react-calendar__navigation__prev-button {
  position: absolute !important;
  left: 1rem !important;
  padding: 4px !important;
}
.react-calendar__navigation__next-button {
  position: absolute !important;
  right: 1rem !important;
  padding: 4px !important;
}
.react-calendar__navigation__next2-button {
  display: none !important;
}
.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__navigation button {
  color: var(--color-black) !important;
  flex-grow: 0 !important;
  padding: 4px !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--color-primary-50) !important;
}
.react-calendar__navigation button[disabled] {
  /* background-color: #f0f0f0 !important; */
  opacity: 0.3 !important;
}
.react-calendar__month-view__weekdays {
  justify-content: center !important;
}
.react-calendar__month-view__weekdays__weekday {
  font-weight: 600 !important;
  padding: 4px !important;
  flex: 0 0 calc(100%/8) !important;
  margin: 2px !important;
}

.react-calendar__viewContainer {
  padding: 4px !important;
  display: flexbox !important;
  justify-content: center !important;
}

.react-calendar__month-view .react-calendar__tile {
  // padding: 4px !important;
  margin: 2px !important;
  flex: 0 0 calc(100%/8) !important;
}

.react-calendar__month-view .react-calendar__tile:disabled {
  opacity: 0.3 !important;
  margin: 2px !important;
  background-color: var(--color-sandstone-40) !important;
  flex: 0 0 calc(100%/8) !important;
  cursor: not-allowed !important;
}

.react-calendar__year-view .react-calendar__tile:disabled {
  opacity: 0.3 !important;
  // background-color: var(--color-sandstone-40) !important;
  cursor: not-allowed !important;
}

abbr[title] {
  text-decoration: none !important;
}
.react-calendar__month-view__days {
  justify-content: center !important;
}

/* .react-calendar__month-view__days__day--weekend {
 color: #d10000 !important;
} */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--color-primary-50) !important;
  color: var(--color-primary-300) !important;
  border-radius: 0px !important;
}
/* today's date */
.react-calendar__tile--now {
  // background: #183c4120 !important;
  background: var(--color-primary-50) !important;
  border-radius: 0px !important;
  color: var(--color-primary-300) !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--color-primary-50) !important;
  border-radius: 0px !important;
  color: var(--color-primary-300) !important;
}
.react-calendar__tile--hasActive{
  // background: var(--color-primary-50) !important;
  // background: #183c4120 !important;
  background: var(--color-primary-300) !important;
  color: var(--color-white) !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--color-primary-50) !important;
}
.react-calendar__tile--active {
  background: var(--color-primary-300) !important;
  border-radius: 0px !important;
  color: var(--color-white) !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--color-primary-300) !important;
  color: var(--color-white) !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--color-primary-50) !important;
}
/* selected date */
.react-calendar__tile--range {
  background: var(--color-primary-300) !important;
  color: var(--color-white) !important;
  border-radius: 0 !important;
}
.react-calendar__tile--rangeStart {
  border-radius: 0px !important;
  background: var(--color-primary-300) !important;
  color: var(--color-white) !important;
}
.react-calendar__tile--rangeEnd {
  border-radius: 0px !important;
  background: var(--color-primary-300) !important;
  color: var(--color-white) !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  /* opacity: 0.7 !important; */
  // color: var(--color-whitesmoke-500) !important;
  visibility: hidden !important;
}
/* .react-calendar__month-view__days__day--weekend {
  color: var(--color-primary-300) !important;
  font-weight: 700 !important;
} */

@media screen and (min-width: 480px) {
  .react-calendar__month-view__weekdays__weekday {
    padding: 6px !important;
  }
    .react-calendar__tile {
    padding: 6px !important;
  }
  .react-calendar__viewContainer {
    padding: 0 !important;
    padding-bottom: 8px !important;
  }
}

@media screen and (min-width: 1024px) {
  .react-calendar__month-view__weekdays__weekday {
    padding: 8px !important;
  }
    .react-calendar__tile {
    padding: 8px !important;
  }

}
